import { ApolloError, useMutation } from '@apollo/client';
import { Alert, FullBleed } from '@phx/design-system';
import { useFullNameFieldValidation } from '@phx/design-system/hooks';
import { KnownErrorCode } from '@phx/instrumentation/react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import {
    type VerifyFormDetails,
    VerifyFormProvider,
    useVerifyForm,
} from '../../../components/cabinet/prescription/verify/verify-form';
import { useVerifyFormSchema } from '../../../components/cabinet/prescription/verify/verify-form/use-verify-form-schema';
import { VerifyPrescription } from '../../../components/cabinet/prescription/verify/VerifyPrescription';
import { VerifyPrescriptionNoAttempts } from '../../../components/cabinet/prescription/verify/VerifyPrescriptionNoAttempts';
import { getFragment } from '../../../graphql';
import {
    ClaimPrescriberOrderForNewPatientDocument,
    GetPrescriberOrdersByPatientDocument,
    GetPrescriberOrdersDocument,
    GetUnclaimedPrescriberOrderDocument,
    PrescriberOrderStatus,
} from '../../../graphql/generated/graphql';
import { usePatientContext } from '../../../hooks/use-patient-context';
import { QueryLoader } from '../../../loaders';
import { getAbsoluteRoute } from '../../../util';
import { getErrorCode } from '../../../util/get-error-code';
import routes from '../../app-routes';

const maxVerifyAttempts = 5;

export const VerifyPrescriptionRoute = () => {
    const { prescriptionId } = useParams();
    if (prescriptionId === undefined) {
        // TODO: Better validation
        throw Error('Prescription ID is required');
    }
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { primaryPatientId, scopedPatientId } = usePatientContext();

    const refetchQueries = [
        {
            query: GetPrescriberOrdersDocument,
            variables: { patientId: primaryPatientId },
        },
        {
            query: GetPrescriberOrdersByPatientDocument,
            variables: {
                patientId: scopedPatientId,
                input: { statusFilter: [PrescriberOrderStatus.New] },
            },
        },
        {
            query: GetUnclaimedPrescriberOrderDocument,
            variables: { prescriberOrderId: prescriptionId },
        },
    ];

    const [submissionError, setSubmissionError] = useState<string | null>(null);
    const [mismatchFailures, setMismatchFailures] = useState<
        number | undefined
    >();
    const noAttemptsLeft = (mismatchFailures ?? 0) >= maxVerifyAttempts;
    const form = useVerifyForm({
        initialValues: {
            firstName: '',
            lastName: '',
            dob: '',
            attest: false,
        },
        schema: useVerifyFormSchema(),
        validateInputOnBlur: true,
        onValuesChange: () => {
            setSubmissionError(null);
        },
    });

    useFullNameFieldValidation(form);

    const [ClaimPrescriberOrderForNewPatient] = useMutation(
        ClaimPrescriberOrderForNewPatientDocument,
        {
            refetchQueries,
            awaitRefetchQueries: true,
        }
    );

    const handleAttemptFailures = (count: number) => {
        setMismatchFailures(count);
        if (count === 0) {
            setSubmissionError(null);
        } else {
            setSubmissionError(
                t('verifyPrescription.mismatchError', {
                    count: maxVerifyAttempts - count,
                })
            );
        }
    };

    const handleMerge = useCallback(async (formData: VerifyFormDetails) => {
        const { firstName, lastName, dob } = formData;
        const [month, day, year] = dob.split('/');

        try {
            await ClaimPrescriberOrderForNewPatient({
                variables: {
                    prescriberOrderId: prescriptionId,
                    patientDetails: {
                        firstName,
                        lastName,
                        dateOfBirth: `${year}-${month}-${day}`,
                    },
                },
            });
        } catch (error: unknown) {
            if (error instanceof ApolloError) {
                if (getErrorCode(error) === KnownErrorCode.VerifyDoesNotMatch) {
                    handleAttemptFailures((mismatchFailures ?? 0) + 1);
                }
                return;
            }

            setSubmissionError(t('verifyPrescription.error'));
            return;
        }

        navigate(getAbsoluteRoute('cabinet.rxId.root', { prescriptionId }));
    }, []);

    const Loader = QueryLoader<typeof GetUnclaimedPrescriberOrderDocument>;
    return (
        <Loader
            query={GetUnclaimedPrescriberOrderDocument}
            variables={{ prescriberOrderId: prescriptionId }}
            render={(data) => {
                if (data.unclaimedPrescriberOrder) {
                    const prescriberOrder = getFragment(
                        data.unclaimedPrescriberOrder
                    );

                    handleAttemptFailures(
                        prescriberOrder.verificationAttempts ?? 0
                    );

                    const errorType = noAttemptsLeft ? 'error' : 'warning';
                    return (
                        <>
                            {submissionError && (
                                <FullBleed
                                    breakpoint="md"
                                    color={errorType}
                                    spacing="none"
                                >
                                    <Alert severity={errorType}>
                                        {submissionError}
                                    </Alert>
                                </FullBleed>
                            )}
                            {noAttemptsLeft ? (
                                <VerifyPrescriptionNoAttempts
                                    prescriberOrder={prescriberOrder}
                                />
                            ) : (
                                <VerifyFormProvider form={form}>
                                    <VerifyPrescription
                                        prescriberOrder={prescriberOrder}
                                        claimPrescription={handleMerge}
                                    />
                                </VerifyFormProvider>
                            )}
                        </>
                    );
                }
                return <Navigate to={routes.home} />;
            }}
        />
    );
};
