// src/client/components/AuthProvider.tsx
import { Body1 as Body12, Button as Button2, Modal as Modal2, Stack as Stack2 } from "@phx/design-system";
import { useIdle } from "@phx/design-system/hooks";
import {
  createContext,
  useCallback as useCallback2,
  useEffect as useEffect2
} from "react";
import { useTranslation as useTranslation2 } from "react-i18next";

// ../../../node_modules/jose/dist/browser/lib/buffer_utils.js
var encoder = new TextEncoder;
var decoder = new TextDecoder;
var MAX_INT32 = 2 ** 32;

// ../../../node_modules/jose/dist/browser/runtime/base64url.js
var decodeBase64 = (encoded) => {
  const binary = atob(encoded);
  const bytes = new Uint8Array(binary.length);
  for (let i = 0;i < binary.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  return bytes;
};
var decode = (input) => {
  let encoded = input;
  if (encoded instanceof Uint8Array) {
    encoded = decoder.decode(encoded);
  }
  encoded = encoded.replace(/-/g, "+").replace(/_/g, "/").replace(/\s/g, "");
  try {
    return decodeBase64(encoded);
  } catch (_a) {
    throw new TypeError("The input to be decoded is not correctly encoded.");
  }
};

// ../../../node_modules/jose/dist/browser/util/errors.js
class JOSEError extends Error {
  static get code() {
    return "ERR_JOSE_GENERIC";
  }
  constructor(message) {
    var _a;
    super(message);
    this.code = "ERR_JOSE_GENERIC";
    this.name = this.constructor.name;
    (_a = Error.captureStackTrace) === null || _a === undefined || _a.call(Error, this, this.constructor);
  }
}
class JWTInvalid extends JOSEError {
  constructor() {
    super(...arguments);
    this.code = "ERR_JWT_INVALID";
  }
  static get code() {
    return "ERR_JWT_INVALID";
  }
}

// ../../../node_modules/jose/dist/browser/lib/is_object.js
function isObjectLike(value) {
  return typeof value === "object" && value !== null;
}
function isObject(input) {
  if (!isObjectLike(input) || Object.prototype.toString.call(input) !== "[object Object]") {
    return false;
  }
  if (Object.getPrototypeOf(input) === null) {
    return true;
  }
  let proto = input;
  while (Object.getPrototypeOf(proto) !== null) {
    proto = Object.getPrototypeOf(proto);
  }
  return Object.getPrototypeOf(input) === proto;
}

// ../../../node_modules/jose/dist/browser/util/base64url.js
var decode2 = decode;

// ../../../node_modules/jose/dist/browser/util/decode_jwt.js
function decodeJwt(jwt) {
  if (typeof jwt !== "string")
    throw new JWTInvalid("JWTs must use Compact JWS serialization, JWT must be a string");
  const { 1: payload, length } = jwt.split(".");
  if (length === 5)
    throw new JWTInvalid("Only JWTs using Compact JWS serialization can be decoded");
  if (length !== 3)
    throw new JWTInvalid("Invalid JWT");
  if (!payload)
    throw new JWTInvalid("JWTs must contain a payload");
  let decoded;
  try {
    decoded = decode2(payload);
  } catch (_a) {
    throw new JWTInvalid("Failed to base64url decode the payload");
  }
  let result;
  try {
    result = JSON.parse(decoder.decode(decoded));
  } catch (_b) {
    throw new JWTInvalid("Failed to parse the decoded payload as JSON");
  }
  if (!isObject(result))
    throw new JWTInvalid("Invalid JWT Claims Set");
  return result;
}
// src/client/hooks/use-auth-tokens.ts
import { useRef, useSyncExternalStore } from "react";
var CSRF_STATE_KEY = "secureState";
var TOKEN_LOCALSTORAGE_KEY = "tokens";
var clearStoredTokens = () => {
  window.localStorage.removeItem(CSRF_STATE_KEY);
  window.localStorage.removeItem(TOKEN_LOCALSTORAGE_KEY);
  window.dispatchEvent(new StorageEvent("storage", {
    key: TOKEN_LOCALSTORAGE_KEY,
    newValue: null
  }));
};
var updateStoredTokens = (payload) => {
  try {
    const newToken = decodeJwt(payload.accessToken);
    const newValue = {
      accessToken: {
        ...newToken,
        raw: payload.accessToken
      },
      refreshToken: payload.refreshToken,
      exp: newToken.exp * 1000
    };
    window.localStorage.removeItem(CSRF_STATE_KEY);
    window.localStorage.setItem(TOKEN_LOCALSTORAGE_KEY, JSON.stringify(newValue));
    window.dispatchEvent(new StorageEvent("storage", {
      key: TOKEN_LOCALSTORAGE_KEY,
      newValue: JSON.stringify(newValue)
    }));
  } catch (error) {
    console.error(error, JSON.stringify(payload));
  }
};
var useAuthTokens = () => {
  const latestKey = useRef(null);
  const latestValue = useRef(null);
  const localStore = {
    getSnapshot: () => {
      const storedData = localStorage.getItem(TOKEN_LOCALSTORAGE_KEY);
      if (!storedData) {
        latestKey.current = null;
        latestValue.current = null;
        return null;
      }
      try {
        if (storedData === latestKey.current && latestValue.current !== null) {
          return latestValue.current;
        }
        latestKey.current = storedData;
        latestValue.current = JSON.parse(storedData);
        return latestValue.current;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    subscribe: (listener) => {
      window.addEventListener("storage", listener);
      return () => void window.removeEventListener("storage", listener);
    }
  };
  return useSyncExternalStore(localStore.subscribe, localStore.getSnapshot);
};

// src/client/util/pkce.ts
var generateCodeVerifier = (len = 64) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let codeVerifier = "";
  for (let i = 0;i < len; i++) {
    codeVerifier += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return codeVerifier;
};
var generateChallenge = async (codeVerifier) => {
  const encoder2 = new TextEncoder;
  const data = encoder2.encode(codeVerifier);
  const digest = await crypto.subtle.digest("SHA-256", data);
  const uint8Array = Array.from(new Uint8Array(digest));
  const base64String = window.btoa(String.fromCharCode.apply(null, uint8Array));
  return base64String.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
};
var generatePkceConfig = async () => {
  const codeVerifier = generateCodeVerifier(64);
  const challenge = await generateChallenge(codeVerifier);
  return { challenge, codeVerifier };
};

// src/client/util/tokens.ts
var FIVE_MINUTES = 5 * 60 * 1000;
var SIXTY_SECONDS = 60 * 1000;
var THIRTY_SECONDS = 30 * 1000;
var fetchAuthToken = async (code, clientId, tokenUrl, codeVerifier, redirectUri) => {
  const formData = new URLSearchParams;
  formData.append("client_id", clientId);
  formData.append("code", code);
  formData.append("grant_type", "authorization_code");
  formData.append("redirect_uri", redirectUri);
  formData.append("code_verifier", codeVerifier);
  return fetch(tokenUrl, {
    method: "POST",
    headers: {
      "content-type": "application/x-www-form-urlencoded"
    },
    body: formData.toString()
  });
};
var fetchRefreshToken = async (code, clientId, tokenUrl) => {
  const formData = new URLSearchParams;
  formData.append("client_id", clientId);
  formData.append("grant_type", "refresh_token");
  formData.append("refresh_token", code);
  return fetch(tokenUrl, {
    method: "POST",
    headers: {
      "content-type": "application/x-www-form-urlencoded"
    },
    body: formData.toString()
  });
};
var performRefresh = async (refreshToken, clientId, tokenUrl) => {
  const response = await fetchRefreshToken(refreshToken, clientId, tokenUrl);
  if (!response.ok) {
    throw new Error;
  }
  const jwt = await response.json();
  if ("error" in jwt) {
    console.warn(jwt.error);
    jwt.error_description && console.warn(jwt.error_description);
    let err = jwt.error;
    if (jwt.error_description) {
      err += ` -- ${jwt.error_description}`;
    }
    throw new Error(err);
  }
  if (jwt.access_token && jwt.refresh_token) {
    updateStoredTokens({
      accessToken: jwt.access_token,
      refreshToken: jwt.refresh_token
    });
  } else {
    throw new Error("access token and/or refresh token missing from response");
  }
};
var getOrRefreshToken = async (clientId, tokenUrl) => {
  const storedData = localStorage.getItem(TOKEN_LOCALSTORAGE_KEY);
  if (!storedData || !clientId || !tokenUrl) {
    return null;
  }
  try {
    const tokens = JSON.parse(storedData);
    const expiry = tokens.exp;
    if (!tokens.accessToken) {
      throw new Error("this should not be possible");
    }
    if (expiry > Date.now() + SIXTY_SECONDS) {
      return tokens.accessToken.raw;
    }
    await performRefresh(tokens.refreshToken, clientId, tokenUrl);
    return getOrRefreshToken(clientId, tokenUrl);
  } catch {
    clearStoredTokens();
  }
  return null;
};

// src/client/util/login.ts
var AUTH_FLOW = {
  Deeplink: 1,
  Employer: 2,
  CreateAccount: 3,
  SignIn: 4,
  UpdateProfile: 5
};
var initiateLoginChallenge = async ({
  mustReauthenticate = false,
  authUrl,
  clientId,
  redirectUri,
  returnUrl,
  state
}) => {
  const { challenge, codeVerifier } = await generatePkceConfig();
  const authState = {
    ...state,
    codeVerifier,
    currentUrl: returnUrl,
    mustReauthenticate,
    stateKey: CSRF_STATE_KEY
  };
  const payload = window.btoa(JSON.stringify(authState));
  localStorage.setItem(CSRF_STATE_KEY, payload);
  const newUrl = new URL(authUrl);
  newUrl.searchParams.set("client_id", clientId);
  newUrl.searchParams.set("redirect_uri", redirectUri);
  newUrl.searchParams.set("response_type", "code");
  newUrl.searchParams.set("state", payload);
  newUrl.searchParams.set("code_challenge_method", "S256");
  newUrl.searchParams.set("code_challenge", challenge);
  window.location.replace(decodeURIComponent(newUrl.toString()));
};
var validateIncomingState = (state) => {
  try {
    const decodedState = JSON.parse(window.atob(state));
    const localState = localStorage.getItem(decodedState.stateKey);
    if (!localState) {
      return null;
    }
    const decodedLocalState = JSON.parse(window.atob(localState));
    if (decodedState.currentUrl === decodedLocalState.currentUrl && decodedState.codeVerifier === decodedLocalState.codeVerifier) {
      return decodedLocalState;
    }
  } catch {
  }
  return null;
};
var processLoginResponse = async ({
  clientId,
  code,
  redirectUri,
  state = "",
  tokenUrl
}) => {
  if (!code) {
    return { error: "response code is missing to get the tokens" };
  }
  const authState = validateIncomingState(state);
  if (!authState) {
    return {
      error: "State does not match while getting code. Possible CSRF attack"
    };
  }
  const response = await fetchAuthToken(code, clientId, tokenUrl, authState.codeVerifier, redirectUri);
  const jwt = await response.json();
  if ("error" in jwt) {
    return {
      error: jwt.error,
      errorDescription: jwt.error_description
    };
  }
  if (jwt.access_token && jwt.refresh_token) {
    updateStoredTokens({
      accessToken: jwt.access_token,
      refreshToken: jwt.refresh_token
    });
    return { nextUrl: authState.currentUrl };
  }
  return { error: "access or refresh token is missing" };
};

// src/client/components/AuthHeartbeat.tsx
import { useEffect, useState } from "react";

// /home/vsts/work/1/s/node_modules/@phx/browser-utils/dist/index.js
var r = (t) => {
  const e = localStorage.getItem(t);
  if (!e)
    return;
  try {
    const o = JSON.parse(e);
    if (o === null) {
      localStorage.removeItem(t);
      return;
    }
    return o;
  } catch {
    localStorage.removeItem(t);
  }
};
var m = (t, e) => {
  if (e === undefined || e === null) {
    localStorage.removeItem(t);
    return;
  }
  if (typeof e === "object") {
    localStorage.setItem(t, JSON.stringify(e));
    return;
  }
  localStorage.setItem(t, String(e));
};

// src/client/hooks/use-token-refresh-error.ts
import { useSyncExternalStore as useSyncExternalStore2 } from "react";
var REFRESH_ERROR_KEY = "refresh_error";
var clearRefreshError = () => {
  m(REFRESH_ERROR_KEY, null);
  window.dispatchEvent(new StorageEvent("storage", {
    key: REFRESH_ERROR_KEY,
    newValue: null
  }));
};
var setRefreshError = () => {
  m(REFRESH_ERROR_KEY, "true");
  window.dispatchEvent(new StorageEvent("storage", {
    key: REFRESH_ERROR_KEY,
    newValue: "true"
  }));
};
var useTokenRefreshError = () => {
  const localStore = {
    getSnapshot: () => {
      const storedData = r(REFRESH_ERROR_KEY);
      return !!storedData;
    },
    subscribe: (listener) => {
      window.addEventListener("storage", listener);
      return () => void window.removeEventListener("storage", listener);
    }
  };
  return useSyncExternalStore2(localStore.subscribe, localStore.getSnapshot);
};

// src/client/components/AuthHeartbeat.tsx
var AuthHeartbeat = ({
  clientId,
  expiry,
  logoutUrl,
  refreshToken,
  tokenUrl
}) => {
  const [nextCheck, setNextCheck] = useState(-1);
  useEffect(() => {
    if (expiry > Date.now() + SIXTY_SECONDS) {
      setTimeout(() => {
        setNextCheck(Date.now() + THIRTY_SECONDS);
      }, THIRTY_SECONDS);
      return;
    }
    try {
      const doRefresh = async () => {
        const response = await fetchRefreshToken(refreshToken, clientId, tokenUrl);
        if (!response.ok) {
          throw new Error;
        }
        const jwt = await response.json();
        if ("error" in jwt) {
          console.warn(jwt.error);
          jwt.error_description && console.warn(jwt.error_description);
          let err = jwt.error;
          if (jwt.error_description) {
            err += ` -- ${jwt.error_description}`;
          }
          throw new Error(err);
        }
        if (jwt.access_token && jwt.refresh_token) {
          updateStoredTokens({
            accessToken: jwt.access_token,
            refreshToken: jwt.refresh_token
          });
        } else {
          throw new Error("access token and/or refresh token missing from response");
        }
      };
      doRefresh();
    } catch {
      setRefreshError();
      window.location.href = logoutUrl;
    }
  }, [clientId, expiry, nextCheck, refreshToken, tokenUrl]);
  return null;
};

// src/client/components/AuthRefreshError.tsx
import { Body1, Button, Modal, Stack } from "@phx/design-system";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

// src/client/hooks/use-auth-context.ts
import { useContext } from "react";
var useAuthContext = () => {
  const ctx = useContext(AuthContext);
  if (ctx === null) {
    throw new Error("useAuthContext() cannot be called outside of an AuthProvider");
  }
  return ctx;
};

// src/client/components/AuthRefreshError.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var AuthRefreshError = () => {
  const { t } = useTranslation("auth");
  const { signIn } = useAuthContext();
  const hasRefreshError = useTokenRefreshError();
  const clearAndSignIn = useCallback(() => {
    clearRefreshError();
    signIn();
  }, [signIn]);
  if (!hasRefreshError) {
    return null;
  }
  return /* @__PURE__ */ jsx(Modal, {
    opened: true,
    onClose: clearRefreshError,
    title: t("errors.tokenRefresh.heading"),
    children: /* @__PURE__ */ jsxs(Stack, {
      gap: "sm",
      children: [
        /* @__PURE__ */ jsx(Body1, {
          children: t("errors.tokenRefresh.message")
        }),
        /* @__PURE__ */ jsx(Stack, {
          gap: "xs",
          children: /* @__PURE__ */ jsx(Button, {
            fullWidth: true,
            onClick: clearAndSignIn,
            children: t("errors.tokenRefresh.cta")
          })
        })
      ]
    })
  });
};

// src/client/components/AuthProvider.tsx
import { jsx as jsx2, jsxs as jsxs2 } from "react/jsx-runtime";
var AuthContext = createContext(null);
var SIGNOUT_DELAY = 500;
var AuthProvider = ({
  children,
  authUrl,
  autoLogout = FIVE_MINUTES,
  clientId,
  logoutUrl = "/logout",
  redirectUri,
  tokenUrl,
  rootUrl
}) => {
  const { t } = useTranslation2("auth");
  const tokens = useAuthTokens();
  const isIdle = useIdle(autoLogout, {
    initialState: false,
    events: ["click", "keypress"]
  });
  const showIdleTimeout = useIdle(autoLogout - SIXTY_SECONDS, {
    initialState: false,
    events: ["click", "keypress"]
  });
  const expiry = tokens?.exp;
  const refreshToken = tokens?.refreshToken;
  const isAuthenticated = Boolean(tokens?.accessToken);
  const signIn = useCallback2((returnUrl = "", state = {}) => {
    initiateLoginChallenge({
      authUrl,
      clientId,
      redirectUri,
      returnUrl: returnUrl === "" ? redirectUri : returnUrl,
      state
    });
  }, [authUrl, clientId, redirectUri]);
  const signUp = useCallback2((returnUrl = "", state = {}) => {
    signIn(returnUrl, {
      ...state,
      flow: AUTH_FLOW.CreateAccount
    });
  }, [signIn]);
  const signOut = useCallback2((returnUrl) => {
    clearStoredTokens();
    const timeout = setTimeout(() => {
      if (returnUrl) {
        window.location.href = returnUrl;
      } else {
        window.location.href = rootUrl;
      }
    }, SIGNOUT_DELAY);
    return () => {
      clearTimeout(timeout);
    };
  }, [rootUrl]);
  useEffect2(() => {
    if (isIdle && isAuthenticated) {
      window.location.href = logoutUrl;
    }
  }, [isAuthenticated, isIdle, signOut]);
  const ctx = {
    authUrl,
    clientId,
    isAuthenticated,
    redirectUri,
    rootUrl,
    signIn,
    signOut,
    signUp,
    tokens,
    tokenUrl,
    user: tokens?.accessToken ? { id: tokens.accessToken.sub, token: tokens?.accessToken } : null
  };
  const pollForRefresh = expiry && refreshToken;
  return /* @__PURE__ */ jsxs2(AuthContext.Provider, {
    value: ctx,
    children: [
      children,
      /* @__PURE__ */ jsx2(AuthRefreshError, {}),
      pollForRefresh && /* @__PURE__ */ jsx2(AuthHeartbeat, {
        clientId,
        expiry,
        logoutUrl,
        refreshToken,
        tokenUrl
      }),
      isAuthenticated && showIdleTimeout ? /* @__PURE__ */ jsx2(Modal2, {
        opened: true,
        onClose: () => {
        },
        title: t("idle.timeout.heading"),
        children: /* @__PURE__ */ jsxs2(Stack2, {
          gap: "sm",
          children: [
            /* @__PURE__ */ jsx2(Body12, {
              children: t("idle.timeout.message")
            }),
            /* @__PURE__ */ jsx2(Stack2, {
              gap: "xs",
              children: /* @__PURE__ */ jsx2(Button2, {
                fullWidth: true,
                children: t("idle.timeout.cta")
              })
            })
          ]
        })
      }) : null
    ]
  });
};
// src/client/components/AuthorizedOutlet.tsx
import { Outlet, useLocation } from "react-router-dom";
import { jsx as jsx3 } from "react/jsx-runtime";
var AuthorizedRoute = ({ children }) => {
  const { isAuthenticated, signIn } = useAuthContext();
  const { pathname } = useLocation();
  if (!isAuthenticated) {
    signIn(pathname);
    return null;
  }
  return children;
};
var AuthorizedOutlet = () => /* @__PURE__ */ jsx3(AuthorizedRoute, {
  children: /* @__PURE__ */ jsx3(Outlet, {})
});
// src/client/components/RequireAuth.tsx
var RequireAuth = ({
  children,
  fallback = null
}) => {
  const { isAuthenticated } = useAuthContext();
  if (!isAuthenticated) {
    return fallback;
  }
  return children;
};
// src/client/components/routes/LoginCallback.tsx
import { Overlay } from "@phx/design-system";
import { useEffect as useEffect3, useRef as useRef2 } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { jsx as jsx4 } from "react/jsx-runtime";
var LoginCallback = () => {
  const { clientId, redirectUri, rootUrl, tokens, tokenUrl } = useAuthContext();
  const accessToken = tokens?.accessToken ?? null;
  const isLoggingIn = useRef2(false);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  useEffect3(() => {
    if (accessToken || isLoggingIn.current) {
      return;
    }
    isLoggingIn.current = true;
    try {
      const code = params.get("code");
      const state = params.get("state");
      const loginError = params.get("error");
      if (!code || !state || loginError) {
        throw new Error(loginError ?? "Unknown login error");
      }
      const runCallback = async () => {
        const response = await processLoginResponse({
          clientId,
          code,
          redirectUri,
          state,
          tokenUrl
        });
        if ("error" in response) {
          console.warn(response.error);
          response.errorDescription && console.warn(response.errorDescription);
          let err = response.error;
          if (response.errorDescription) {
            err += ` -- ${response.errorDescription}`;
          }
          throw new Error(err);
        }
        if (response.nextUrl && response.nextUrl !== "/") {
          navigate(response.nextUrl);
        } else {
          navigate(rootUrl);
        }
      };
      runCallback();
    } catch (error) {
      console.warn(error);
      navigate(rootUrl);
    }
  }, [accessToken, params]);
  return /* @__PURE__ */ jsx4(Overlay, {
    loader: true
  });
};

// src/client/components/routes/LogoutRoute.tsx
import { useLayoutEffect } from "react";
var LogoutRoute = ({ returnUrl }) => {
  const ctx = useAuthContext();
  useLayoutEffect(() => {
    ctx.signOut(returnUrl);
  }, []);
  return null;
};
export {
  useAuthContext,
  getOrRefreshToken,
  RequireAuth,
  LogoutRoute,
  LoginCallback,
  AuthorizedRoute,
  AuthorizedOutlet,
  AuthProvider,
  AUTH_FLOW
};

//# debugId=896B4F295E4AEC7764756E2164756E21
